import { NavLink, useParams } from 'react-router-dom'
import styles from './Tournament.module.scss'
import { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import HeaderMob from '../../../components/HeaderMob/HeaderMob'
import Footer from '../../../components/Footer/Footer'
import Form from '../../../components/modals/Form/Form'
import Offer from '../../../components/modals/Offer/Offer'
import Bonus from '../../../components/modals/Bonus/Bonus'
import OfferRules from '../../../components/modals/OfferRules/OfferRules'
import OfferRulesAccept from '../../../components/modals/OfferRulesAccept/OfferRulesAccept'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { updateAuth } from '../../../slices/authSlice'
import Toast from '../../../components/Toast/Toast'
import { toast } from 'react-toastify'
import dota from '../../../assets/images/dota.svg'
import cs from '../../../assets/images/cs.svg'
import Button from '../../../components/Button/Button'

const SPREADSHEET_ID = '1G8zbSbpvlUYmK04TfiF0Bjjgj013dxyMdstZLF0WBQY'
const SHEET_ID = 'Лист1'
const API_KEY = 'AIzaSyCJ-OwS_CHE1aIcz4osT4bPa1Q6Mc2AolE'

const Tournament = () => {
  const { id } = useParams()

  const [events, setEvents] = useState([])

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [showBonus, setShowBonus] = useState(false);
  const [showOfferRules, setShowOfferRules] = useState(false);

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const publicOfferAccepted = useSelector(state => state?.auth?.user?.public_offer_accepted);

  const { t } = useTranslation(['main', 'notifications']);

  const dispatch = useDispatch();

  const handleLoginClick = () => {
    document.body.style.overflow = 'hidden';
    setIsFormOpen(true);
    setShowLogin(true);
    setShowHelp(false);
  };

  const handleRegisterClick = () => {
    document.body.style.overflow = 'hidden';
    setIsFormOpen(true);
    setShowLogin(false);
    setShowOffer(false);
    setShowBonus(false);
    setShowOfferRules(false);
  };

  const handleHelpClick = () => {
    setShowHelp(true);
  };

  const handleOfferClick = () => {
    setIsFormOpen(false);
    setShowOffer(true);
  };

  const handleBonusClick = () => {
    setIsFormOpen(false);
    setShowBonus(true);
  };

  const handleOfferRulesClick = () => {
    setIsFormOpen(false);
    setShowOfferRules(true);
  };

  const closeForm = () => {
    document.body.style.overflow = 'auto';
    setIsFormOpen(false);
    setShowHelp(false);
    setShowOffer(false);
    setShowBonus(false);
    setShowOfferRules(false);
  };

  useEffect(() => {
    const currentURL = window.location.href;

    const firstLogin = async () => {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/profile`, {
          "first_login": false
        }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        if (response.data) {
          dispatch(updateAuth({
            isAuthenticated: true,
            user: response.data
          }));

          localStorage.setItem('currentPage', 1);
        }

      } catch (error) {
        console.error(error);
      }
    };

    if (currentURL.includes('?steam_auth=success')) {
      firstLogin();
      toast(<Toast message={t('notifications:steam_attached')} />, {
        hideProgressBar: true
      });
    }
  }, [dispatch, t, isAuthenticated]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_ID}?alt=json&key=${API_KEY}`;

      try {
        const response = await fetch(url);
        const result = await response.json();

        result.values.shift()

        const parsedEvents = result.values.map((event) => {
          const [dayStart, monthStart, yearStart] = event[0]?.trim().split('.') || [];
          const [dayEnd, monthEnd, yearEnd] = event[1]?.trim().split('.') || [];

          const startDate = dayStart && monthStart && yearStart
            ? new Date(`${yearStart}-${monthStart}-${dayStart}`)
            : null;

          const endDate = dayEnd && monthEnd && yearEnd
            ? new Date(`${yearEnd}-${monthEnd}-${dayEnd}`)
            : null

          return {
            startDate,
            endDate,
            name: event[2]?.trim(),
            gameType: event[3]?.trim(),
            prize: event[4]?.trim(),
            description: event[5]?.trim(),
            img: event[6]?.trim(),
            url: event[7]?.trim()
          };
        });

        setEvents(parsedEvents)
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, [])

  const formatDate = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString);
    if (isNaN(date)) return null

    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  return (
    <div>
      <Header onLoginClick={handleLoginClick}
        onRegisterClick={handleRegisterClick} />
      <HeaderMob onLoginClick={handleLoginClick}
        onRegisterClick={handleRegisterClick} />

      <div className={styles.container} style={{ backgroundImage: `url(${events[id]?.img})` }}>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <div className={styles.title}>
              Турнир {events[id]?.name}
            </div>
            <div className={styles.modalGame}>
              {
                events[id]?.gameType === 'Dota 2'
                  ?
                  <div className={styles.modalGameItem}>
                    <div>
                      <img src={dota} alt="Dota 2" />
                    </div>
                    <div>
                      Dota 2
                    </div>
                  </div>
                  :
                  <div className={`${styles.modalGameItem} ${styles.modalGameItemCS}`}>
                    <div>
                      <img src={cs} alt="CS 2" />
                    </div>
                    <div>
                      CS 2
                    </div>
                  </div>
              }
            </div>

            <div className={styles.dates}>
              <div>
                <div>
                  Дата начала:
                </div>
                <div className={styles.date}>
                  {formatDate(events[id]?.startDate)}
                </div>
              </div>
              <div>
                <div>
                  Дата окончания:
                </div>
                <div className={styles.date}>
                  {formatDate(events[id]?.endDate)}
                </div>
              </div>
            </div>

            <div className={styles.description}>
              {events[id]?.description}
            </div>

            <NavLink to={events[id]?.url} target='_blank'>
              <Button title="Перейти к матчам" />
            </NavLink>

          </div>
        </div>
      </div>

      <Footer />
      {isFormOpen && (
        <Form showLogin={showLogin}
          closeForm={closeForm}
          onLoginClick={handleLoginClick}
          onRegisterClick={handleRegisterClick}
          onHelpClick={handleHelpClick}
          showHelp={showHelp}
          onOfferClick={handleOfferClick}
          onBonusClick={handleBonusClick}
          showOfferRules={showOfferRules}
          onOfferRulesClick={handleOfferRulesClick} />
      )}
      {showOffer && <Offer closeForm={closeForm}
        onRegisterClick={handleRegisterClick} />}
      {showBonus && <Bonus closeForm={closeForm}
        onRegisterClick={handleRegisterClick} />}
      {showOfferRules && <OfferRules closeForm={closeForm}
        onRegisterClick={handleRegisterClick} />}
      {isAuthenticated && !publicOfferAccepted && <OfferRulesAccept />}
    </div>
  )
}

export default Tournament